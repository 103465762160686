import React, {useState} from 'react';
import {useForm} from "react-hook-form";
import Select from 'react-select'
import {navigate} from 'gatsby';

import TextFormatter from "../../utils/TextFormatter";
import FormError from "../shared/form-error/FormError";
import {FormMessageHelper} from "../../utils/FormMessage.helper";
import {MessageEnum} from "../../types/form-message.interface";
import CustomLink from "../shared/custom-link/CustomLink";
import {getCustomSelectStyles} from '../../styles/custom-select.styles';

type SelectType = {
  type: number,
  label: string,
}

export type ContactFormProps = {
  title: string,
  description: string,
  fullnamePlaceholder: string,
  phonePlaceholder: string,
  emailPlaceholder: string,
  subjectPlaceholder: string,
  messagePlaceholder: string,
  agreement: string,
  buttonLabel: string,
  selectOptions: SelectType[],
}

interface IFormState {
  name: string,
  phone: string,
  email: string,
  subject: string,
  category: number,
  message: string,
  agreement: boolean,
}

const ContactForm = ({data}: { data: ContactFormProps }) => {
  const URL = `${process.env.MYTRACKO_API_URL}/form-message`;
  const [requestIsSending, setRequestIsSending] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [selectValue, setSelectValue] = useState<{ value: number, label: string } | null>(null);

  const {register, trigger, reset, setValue, handleSubmit, formState: { errors }} = useForm<IFormState>()
  const onSubmit = handleSubmit((data) => {
    setRequestIsSending(true);
    setErrorMessage(null);

    fetch(URL, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(FormMessageHelper.getBodyRequest(MessageEnum.contact, {...data}))
    })
      .then((res) => {
        setRequestIsSending(false);
        if (res.ok) {
          navigate('/kontakt/dziekujemy/', {state: {title: 'Wiadomość wysłana!', description: 'Odpowiemy na Twoją wiadomość do&nbsp;24&nbsp;godzin.'}});
          setSelectValue(null);
          return reset();
        }

        return res.json();
      })
      .then(data => {
        if (data) throw data.message;
      })
      .catch(e => {
        setErrorMessage(typeof e === 'string' ? e : 'Coś poszło nie tak. Spróbuj ponownie.');
        setRequestIsSending(false);
      })
  })
  const {title, description, selectOptions} = data;
  const {phonePlaceholder, fullnamePlaceholder, emailPlaceholder, messagePlaceholder, subjectPlaceholder} = data;
  const formattedDescription = TextFormatter.concatOrphans(description) || description;

  const selectComponentOptions = selectOptions.map(option => ({value: option.type, label: option.label}));

  const onSelectChange = (e: {value: number, label: string} | null) => {
    setSelectValue(e);
    if (!e) return
    setValue('category', (e.value || 0))
    setValue('subject', e.label)
    const ignorePromise = trigger('category');
  }

  return(
      <div className={'lg:max-w-[550px] w-full lg:w-auto sm:min-w-[370px] shrink-1 flex flex-col justify-center items-center text-center self-baseline'}>
        <div className={'mb-6 w-full text-center md:text-left'}>
          <p className={'font-head text-white text-lg'} dangerouslySetInnerHTML={{__html: formattedDescription}}/>
        </div>

        <form onSubmit={onSubmit} className={'flex flex-col gap-y-1 w-full'}>
          <div className={'grid grid-cols-1 md:grid-cols-2 gap-1 gap-x-3'}>
            <div className={'flex flex-col'}>
              <input
                {...register('name', { required: true })}
                aria-invalid={errors.name ? "true" : "false"}
                type='text'
                id='name'
                className={`pl-3.5 text-sm xs:w-full placeholder:text-neutrals-40 rounded h-12 focus:outline-none block ${errors.name ? 'outline outline-2 outline-error-20' : ''}`}
                placeholder={fullnamePlaceholder}
              />
              <div className={`${errors.name?.type === 'required' ? 'h-6 opacity-100' : 'h-2 opacity-0'} transition-all`}>
                {errors.name?.type === 'required' && <FormError message={'Nie podano imienia i nazwiska'} />}
              </div>
            </div>
            <div className={'flex flex-col'}>
              <input
                type='number'
                {...register('phone', { required: true, min: 100000000, max: 999999999 })}
                id='phone'
                className={`pl-3.5 text-sm xs:!w-full placeholder:text-neutrals-40 rounded h-12 focus:outline-none block ${errors.phone ? 'outline outline-2 outline-error-20' : ''}`}
                placeholder={phonePlaceholder}
              />
              <div className={`${(errors.phone?.type === 'required' || (errors.phone?.type === 'min' || errors.phone?.type === 'max')) ? 'h-6 opacity-100' : 'h-2 opacity-0'} transition-all`}>
                {errors.phone?.type === 'required' && <FormError message={'Nie podano numeru telefonu'}/>}
                {(errors.phone?.type === 'min' || errors.phone?.type === 'max') ? <FormError message={'Numer telefonu powinien składać się z 9 cyfr'}/> : null}
              </div>
            </div>
            <div className={'flex flex-col'}>
              <input
                  {...register('email', { required: true })}
                  type='email'
                  id='email'
                  className={`pl-3.5 text-sm xs:w-full placeholder:text-neutrals-40 rounded h-12 focus:outline-none block ${errors.email ? 'outline outline-2 outline-error-20' : ''}`}
                  placeholder={emailPlaceholder}
                />
              <div className={`${errors.email?.type === 'required' ? 'h-6 opacity-100' : 'h-2 opacity-0'} transition-all`}>
                {errors.email?.type === 'required' && <FormError message={'Nie podano adresu email'}/>}
              </div>
            </div>
            <div>
              <Select
                {...register('category', { required: true })}
                placeholder={subjectPlaceholder}
                options={selectComponentOptions}
                className={`text-left text-sm h-fit ${errors.category ? 'outline outline-2 outline-error-20' : ''} rounded`}
                onChange={(e) => onSelectChange(e)}
                isMulti={false}
                styles={getCustomSelectStyles<{value: number, label: string}>()}
                value={selectValue}
              />
              <div className={`${errors.category?.type === 'required' ? 'h-6 opacity-100' : 'h-2 opacity-0'} transition-all`}>
                {errors.category?.type === 'required' && <FormError message={'Nie wybrano tematu wiadomości'}/>}
              </div>
            </div>
          </div>

          <div className={'flex flex-col'}>
            <textarea
              {...register('message', { required: true })}
              id='message'
              className={`max-h-[10rem] min-h-[2.5rem] text-sm w-full p-2.5 rounded flex-grow h-24 focus:outline-none block placeholder:text-neutrals-40 ${errors.message ? 'outline outline-2 outline-error-20' : ''}`}
              placeholder={messagePlaceholder}
            />
            <div className={`${(errors.message?.type === 'required' || errorMessage) ? 'h-6 opacity-100' : 'h-2 opacity-0'} transition-all`}>
              {errors.message?.type === 'required' && <FormError message={'Nie podano treści wiadomości'}/>}
              {errorMessage && <FormError message={errorMessage}/>}
            </div>
          </div>

          <button type="submit" disabled={requestIsSending} className={'mtr-btn-large mtr-btn-[secondary] w-fit ml-auto'}>
            <span>WYŚLIJ WIADOMOŚĆ</span>
          </button>
        </form>
      </div>
  )
}

export default ContactForm;
