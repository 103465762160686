import React, {useContext} from 'react';

import CustomLink from '../shared/custom-link/CustomLink';
import ContactInformation from '../../assets/contact-information';
import AcceptancePrivacyPolicy from '../shared/acceptance-privacy-policy/AcceptancePrivacyPolicy';
import TextFormatter from '../../utils/TextFormatter';
import {ContactUsProps} from './ContactUsMap';
import {MapContext} from '../../context/map/map.context';
import Scroller from '../../utils/Scroller';

const ContactUsInformation = ({data}: {data: ContactUsProps}) => {
  const {map} = useContext(MapContext);

  const panToAddress = () => {
    Scroller.scrollTo('contact-us-map', window.innerWidth < 1280 ? 95 : 100);
    map?.panTo({lat: ContactInformation.company.address.lat, lng: ContactInformation.company.address.lng});
  }

  const formattedDescription = TextFormatter.concatOrphans(data.description.data.description) || data.description.data.description;

  return (
    <div className={'flex shrink-1 flex-col lg:max-w-[550px]'}>
      <p className={'text-lg text-white mb-9'} dangerouslySetInnerHTML={{__html: formattedDescription}}/>
      <CustomLink isInternal={false} className={'contact-us--btn'} to={`tel:${ContactInformation.phone}`}>
        <div className={'flex sm:w-16 sm:h-16 w-14 h-14 items-center justify-center border-t-2 border-x-2 border-neutrals-20 shrink-0'}>
          <span className={'mtr-icon text-neutrals-20 !text-2xl lg:!text-3xl'}>call</span>
        </div>
        <p className={'text-base sm:text-xl text-neutrals-90'}>{ContactInformation.phone}</p>
      </CustomLink>
      <CustomLink isInternal={false} className={'contact-us--btn'} to={`mailto:${ContactInformation.email}`}>
        <div className={'flex sm:w-16 sm:h-16 w-14 h-14 items-center justify-center border-t-2 border-x-2 border-neutrals-20 shrink-0'}>
          <span className={'mtr-icon text-neutrals-20 !text-2xl lg:!text-3xl'}>mail</span>
        </div>
        <p className={'text-base sm:text-xl text-neutrals-90 overflow-hidden'}>{ContactInformation.email}</p>
      </CustomLink>
      <button onClick={() => panToAddress()} type={'button'} className={'contact-us--btn cursor-pointer mb-auto'}>
        <div className={'flex sm:w-16 sm:h-16 w-14 h-14 items-center justify-center border-2 border-neutrals-20 shrink-0 mb-auto'}>
          <span className={'mtr-icon text-neutrals-20 !text-2xl lg:!text-3xl'}>location_on</span>
        </div>
        <p className={'text-base sm:text-xl text-neutrals-90 text-left'}>
          {ContactInformation.company.address.street}, {ContactInformation.company.address.zipCode} {ContactInformation.company.address.city}
        </p>
      </button>
    </div>
  )
}

export default ContactUsInformation;
