import React from "react";

import MapComponent from "../map/Map";
import icon from "../../assets/mytracko_marker.png";
import {MapLayersEnum} from "../../context/map/types/maplayers.enum";
import {MapImagesEnum} from "../../context/map/types/map-images.enum";
import {ILngLat} from "../../context/map/types/marker.interface";
import ContactInformation from '../../assets/contact-information';
import './contact-us.scss';
import {DistributionItem} from '../../pages/gdzie-kupic';

export type ContactUsProps = {
  title: string,
  description: {
    data: {
      description: string,
    }
  },
  mail: string,
  address: string,
  phoneNumber: string,
  coordinates: ILngLat,
}

const ContactUsMap = () => {
  const item: DistributionItem = {
    email: ContactInformation.email,
    address: `${ContactInformation.company.address.street}, ${ContactInformation.company.address.zipCode} ${ContactInformation.company.address.city}`,
    phone: ContactInformation.phone,
    name: ContactInformation.company.name,
    id: '',
    coordinates: {
      lat: ContactInformation.company.address.lat,
      lng: ContactInformation.company.address.lng,
    }
  }

  return(
    <section className={'flex items-center overflow-hidden'}>
      <div className={'flex flex-col lg:flex-row gap-9 xl:gap-16 py-12 pr-0 lg:pr-10 w-full items-center justify-center'}>
        <div id={'contact-us-map'} className={'flex w-full lg:min-w-[380px] lg:max-w-[1200px] relative'}>
          <MapComponent
            className={'w-full h-[612px] lg:rounded-lg z-10 bg-white'}
            markers={[item]}
            images={[{id: MapImagesEnum.MARKER_GENERAL, image: icon}]}
            layer={MapLayersEnum.MYTRACKO_COMPANY}
          />
          <div className={'bg-squares hidden lg:flex absolute top-6 right-[-60px] w-[376px] h-[706px]'}></div>
        </div>
      </div>
    </section>
  )
}

export default ContactUsMap;
