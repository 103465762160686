import React from "react";
import {graphql, HeadProps, PageProps} from "gatsby";

import {SiteMetadata} from "../types/site-metadata.type";
import Layout from "../components/Layout";
import {SiteMetadataConfigProps} from "../types/metadata-config-props.type";
import {PageContextType} from "../types/page-context.type";
import ContactForm, {ContactFormProps} from "../components/contact/ContactForm";
import {ILngLat} from "../context/map/types/marker.interface";
import {MapProvider} from "../context/map/map.context";
import ContactUsMap, {ContactUsProps} from "../components/contact/ContactUsMap";
import StructuredData from '../assets/structured-data';
import ContactUsInformation from '../components/contact/ContactUsInformation';
import SocialLinks from '../components/shared/social-links/SocialLinks';
import TextFormatter from '../utils/TextFormatter';
import AcceptancePrivacyPolicy from '../components/shared/acceptance-privacy-policy/AcceptancePrivacyPolicy';

type ContactProps = {
  strapiContact: {
    title: string,
    mail: string,
    address: string,
    phoneNumber: string,
    coordinates: ILngLat,
    siteMetadata: SiteMetadata,
    description: {
      data: {
        description: string,
      },
    },
    contactForm: ContactFormProps,
  }
} & SiteMetadataConfigProps

const Contact = ({data: { strapiContact }, pageContext}: PageProps<ContactProps, PageContextType>) => {
  const {title, description, phoneNumber, mail, address, coordinates, contactForm} = strapiContact;
  const contactUsProps: ContactUsProps = { title, description, address, mail, coordinates, phoneNumber };
  const formattedTitle = TextFormatter.concatOrphans(contactUsProps.title) || contactUsProps.title;

  return (
    <Layout crumbs={pageContext.breadcrumb.crumbs} crumbLabel={strapiContact.siteMetadata.crumbLabel}>
      <MapProvider>
        <section className={'mx-auto max-w-[1920px] bg-primary-60 px-5 sm:px-10 py-12 sm:px-20 md:pb-12 pt-32 overflow-hidden'}>
          <div className={'flex flex-col w-fit mx-auto'}>
            <h1 className={'font-head text-white text-4xl sm:text-5xl mb-10'} dangerouslySetInnerHTML={{__html: formattedTitle}}/>
            <div className={'flex flex-col lg:flex-row w-full justify-center gap-14 lg:gap-20'}>
              <ContactUsInformation data={contactUsProps}/>
              <ContactForm data={contactForm} />
            </div>
            <div className={'flex flex-col lg:flex-row justify-between mt-10 gap-5 items-center'}>
              <AcceptancePrivacyPolicy className={'text-neutrals-90 text-xs sm:text-sm shrink-1'}/>
              <SocialLinks textColor={'neutrals-90'} iconColor={'#303030'} className={'shrink-0 ml-auto'} dimensionsClassName={'h-[52px] w-[52px]'}/>
            </div>
          </div>
        </section>
        <ContactUsMap />
      </MapProvider>
    </Layout>
  )
}

export default Contact;

export const Head = (props: HeadProps<ContactProps>) => {
  const slug = props.location.pathname;
  const siteUrl = props.data.site.siteMetadata.siteUrl;
  const {title, description, crumbLabel} = props.data.strapiContact.siteMetadata;
  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description}/>
      <meta name="robots" content="index, follow"/>
      <meta name="theme-color" content="#24B25B"/>
      {StructuredData([
        {
          name: crumbLabel,
          item: siteUrl + slug
        }
      ])}
    </>
  )
}

export const query = graphql`
  query {
    strapiContact {
      mail
      phoneNumber
      title
      description {
        data {
          description
        }
      }
      address
      coordinates {
        lat
        lng
      }
      contactForm {
        agreement
        buttonLabel
        description
        emailPlaceholder
        fullnamePlaceholder
        messagePlaceholder
        phonePlaceholder
        selectOptions {
          type
          label
        }
        subjectPlaceholder
        title
      }
      siteMetadata {
        title
        description
        crumbLabel
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`
